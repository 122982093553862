import dynamic from 'next/dynamic';
import React, { useEffect, useState } from 'react';

import { getLocationFromLocalStorage } from '../../util/helperFunctions';
import PlayIcon from '../Icons/PlayIcon';
import BookIcon from '../Icons/BookIcon';
// import CoachIcon from '../Icons/CoachIcon';
import LearnIcon from '../Icons/LearnIcon';
import { useRouter } from 'next/router';
import { UseStateValue } from '../../state';
import { isEmpty } from 'lodash';

const ProfileV2 = dynamic(() => import('../Profile/ProfileMobile'), { ssr: false, loading: () => <div className='shimmer w-1/3 h-6 rounded-2xl mx-4' /> });
const NavLogoLocation = dynamic(() => import('./NavLogoLocation'), { ssr: false, loading: () => <div className='shimmer w-1/3 h-6 rounded-2xl mx-4' /> });

const HomeNavBar = ({ hideLocation = false }) => {
  const [linkToCity, setLinkToCity] = useState(null);

  const [{ location }, _] = UseStateValue();

  const router = useRouter();

  useEffect(() => {
    const storedLocation = getLocationFromLocalStorage();
    if (!isEmpty(storedLocation) || location?.locationLatLon?.length) {
      const { city } = storedLocation || { city: null };
      const { location: newCity } = location?.location || 'bangalore';
      setLinkToCity(city || newCity);
    }
  }, [location]);

  const NAVITEMS = [
    {
      id: 1,
      title: 'Play',
      imageSrc: 'https://playo-website.gumlet.io/playo-website-v3/PersonSimpleThrow.png',
      Icon: PlayIcon,
      URL: `/activities?location=${linkToCity || 'bangalore'}`,
      route: ['/activities',"/match/[activityId]"],
      fill: '#3B4540'
    },
    {
      id: 2,
      title: 'Book',
      imageSrc: 'https://playo-website.gumlet.io/playo-website-v3/book.png',
      Icon: BookIcon,
      URL: linkToCity ? `/venues/${linkToCity}` : "/venues",
      route: '/venues',
      fill: '#3B4540'
    },
    {
      id: 3,
      title: 'Train',
      imageSrc: 'https://playo-website.gumlet.io/playo-website-v3/coach.png',
      Icon: LearnIcon,
      URL: '/train',
      route: ['/train',"/train/category/[categoryName]","/train/details"],
      fill: '#3B4540'
    }
  ];

  const selectedItemFill = "#00B562";

  return (
    <header className="bg-white hidden md:block sticky top-0 z-10">
      <nav className="flex justify-between items-center mx-12 xxl:m-auto py-4 max-w-page">
        <NavLogoLocation hideLocation={hideLocation} classNames='xl:min-w-[400px] w-1/3' />
        <div className='flex gap-14 items-center justify-center text-center grow w-1/3'>
          {NAVITEMS.map(nav => {
            let { fill, route } = nav;
            let selectRoute = false;
            let arrayRoute=route instanceof Array;
            if (route === router.route || arrayRoute ) {
              if(arrayRoute && !route.includes(router.route)){
                  selectRoute=false
              }
              else{
                  selectRoute = true;
                  fill = selectedItemFill;
              }
            }
            return (
              <a href={nav.URL} key={nav.id}>
                <div className='flex items-center cursor-pointer'>
                  <div className='w-8 h-8 m-auto'>
                    <nav.Icon height={'2rem'} width={'2rem'} fill={fill} />
                    </div>
                  <span className={`ml-2 text-xl font-medium ${selectRoute ? 'text-[#00B562]' : 'text-main'}`}>{nav.title}</span>
                </div>
              </a>
            )}
          )}
        </div>
        <div className='w-1/3 flex justify-end'>
          <ProfileV2 />
        </div>
      </nav>
    </header> 
  )
}

export default HomeNavBar;
